import { Form, Input, InputNumber, Button, Checkbox, Typography } from 'antd';
import React from 'react';
import axios from 'axios';
import '../App.css';
import { baseURL } from '../API/url';
import {
	Redirect
} from 'react-router-dom';
import { withRouter } from "react-router";


const { Title } = Typography;

const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };
  
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be equal to or less then ${len} digits',
    },
  };
  /* eslint-enable no-template-curly-in-string */
  
  const Register = (props:any) => {

    const onFinish = (values: any) => {
  //    console.log(values);
      const user = values.user;
      const regEmail=user.email.toLowerCase();
      const RegisterUser = {
        email: regEmail,
        password: user.password,
        firstName: user.firstname,
        lastName: user.lastname,
        phoneNumber: user.homephone
      }

      axios.post(baseURL+"/auth/register", RegisterUser, { withCredentials: true })		
      .then((res) => {
          
        if (res.data.error) {
            console.log(res.data.error);
            alert(res.data.error);
            return;
        }
        
     //   console.log(res.data);
        if(res.data) {
            alert('A confirmation email was sent to your email address. Please click the activation link');
            props.history.push('/');
        }
      }, (err)=>{
          console.log('[err] ' + err);
      })


    };

    return (
 
    <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
        <Title level={2} style={{ marginTop: '50px',textAlign: 'center' }}>Registration</Title> 
        <Form.Item name={['user', 'firstname']} label="First Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name={['user', 'lastname']} label="Last Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
         <Form.Item name={['user', 'email']} label="Email" rules={[{ required: true, type: 'email' }]}>
          <Input />
        </Form.Item>
        <Form.Item name={['user', 'homephone']} label="Home Phone" rules={[{ required: true,len:10 }]}>
          <Input />
        </Form.Item>
        <Form.Item name={['user', 'accountnumber']} label="Account Number" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
        name={['user', 'password']}
        label="Password"
        rules={[
          {
            required: true,
          },
          {
            pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d`~!@#$%^&*()_+-=]{8,}$/,
            message: `Password should be minimum eight characters, at least one uppercase letter, one lowercase letter and one number`
          }
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['user','password']}
        hasFeedback
        rules={[
          {
            required: true,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue(['user','password']) === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
          },
        ]}
        {...tailFormItemLayout}
      >
        <Checkbox>
          I have read the <a href="terms-and-conditions.htm" target="_blank" >agreement</a>          
        </Checkbox>
      </Form.Item>
          <Button type="primary" htmlType="submit">
            Register
          </Button>
        </Form.Item>
      </Form>
    );
  };
  
  export default withRouter(Register);