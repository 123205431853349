import React from 'react';
import logo from '../ginkgo.png';
import '../App.css';
import axios from 'axios';
import { baseURL } from '../API/url';
import moment from 'moment';
import { Form, DatePicker, Button, Typography, message, Radio } from 'antd';
import { withRouter } from "react-router";
import { Certificate } from 'crypto';
import { transpileModule } from 'typescript';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const dateFormat = 'MM-DD-YYYY';
const DB_dateFormat = 'YYYY-MM-DD';


class Portfolio extends React.Component<any,any> {
	constructor(props:any) {
		super(props);
		
		this.state = { 
				transactionData: [],
				certData: [],
				transactionStartDate: moment().dayOfYear(1).format(dateFormat),
				transactionEndDate: moment().format(dateFormat),
				certStartDate: moment().dayOfYear(1).format(dateFormat),
				certEndDate: moment().format(dateFormat),
				isAuthenticated: props.isAuthenticated,
				partnersData: [],
				currentAccountRecId: '',
				totalAccountValue: [],
				totalAccountsValue: 0,
				displayAccountNum: '',
				transactionProductId: '',
				certificateProductId: '',
				advisor: '',
				certSub_total: ''
			};
	}
	
	componentDidMount = async() => {
		
		if(!this.state.isAuthenticated) {
			this.props.history.push('/login');
			return;
		}

		try {
			const partners = await axios.get(baseURL+"/api/partnerRec");

			this.setState({partnersData: partners.data, currentAccountRecId: partners.data[0].recId, displayAccountNum: partners.data[0].account })
			const transaction_data = await this.getTransactionData(this.state.transactionStartDate, this.state.transactionEndDate, partners.data[0].recId,this.state.transactionProductId);
			const cert_data = await this.getCertData(this.state.certStartDate, this.state.certEndDate, partners.data[0].recId, this.state.certificateProductId);
			const advisor_data = await this.getAdvisor(partners.data[0].categories);
			this.setState({ transactionData: transaction_data.data, certData: cert_data.data, advisor: advisor_data });
		//	console.log(this.state.certData)
			/* const {total_share_balance, endingCapital} = await this.getTotalPortfolioData(partners.data[0].recId);
			this.setState({totalAccountValue:{ 
				...this.state.totalAccountValue,
				totalShareBalance: total_share_balance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
				endingCapital: endingCapital.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
			}}) */

			await this.countTotalAccountsValue(partners.data.map((partnerData:any)=>(partnerData.recId)))
		} catch (error) {
			alert('No Data Record Was Found');
			this.props.history.push('/profile');
			await this.render();
			return;
		}
		
	}

	getTransactionData = async(transactionStartDate: string, transactionEndDate: string, recId: string, product: string) => {
		const transactionQuery = '?transactionStartDate='+transactionStartDate+'&transactionEndDate='+transactionEndDate+'&recId='+recId+'&productId='+product;
		const transaction_data = await axios.get(baseURL+"/api/transactions"+transactionQuery);
		return transaction_data
	}
	getCertData = async(certStartDate: string, certEndDate: string, recId:string, product: string) => {
		const certQuery = '?certStartDate='+certStartDate+'&certEndDate='+certEndDate+'&recId='+recId+'&productId='+product;;
		const cert_data = await axios.get(baseURL+"/api/certificates"+certQuery);
		let certSubtotal = 0
		if (cert_data.data.length!==0) {
			cert_data.data.forEach((cert:any)=> {
			certSubtotal += Number(cert.transaction[0].shares)
		})}
		// certSubtotal = certSubtotal * 10
		// certSubtotal = certSubtotal * Number(cert_data.data.transaction[0].sharePrice)
			this.setState({certSub_total: Number(certSubtotal.toFixed(2))})
		return cert_data
	}

	getTotalPortfolioData = async(recId: string) => {
		const total_trans = await this.getTransactionData('', '', recId,'');
		if (!total_trans || total_trans.data.length === 0) {
			return {total_share_S1_balance:0, total_share_S2_balance:0, endingCapital:0};
		}
		const total_trans_data = total_trans.data;
		//console.log(total_trans_data)

		let total_share_S1_balance = 0;
		let total_share_S2_balance = 0;
		let endingCapital = 0;
		total_trans_data.forEach((tran:any)=>{
		//	console.log(tran)
			if (tran.partnership.recId==='253a1dc7-3bdc-40d9-98c9-75ca619fb035') {
			total_share_S1_balance += Number(tran.shares);
			endingCapital +=  Number(tran.shares) * tran.partnership.fixedShareValue
			}
			if (tran.partnership.recId==='4097eb52-bcd6-4f56-b470-5b44e2bf6c54') {
				total_share_S2_balance += Number(tran.shares);
				endingCapital +=  Number(tran.shares) * tran.partnership.fixedShareValue
				}
		})
		const partnerInfo = this.state.partnersData.filter((partner:any)=>partner.recId === recId);

		return {total_share_S1_balance, total_share_S2_balance, endingCapital, partnerInfo:partnerInfo[0]};
	}

	 getAdvisor = async(categories: string) => {
		let advisor = ''
		if (!categories) {
			return advisor
		}
		if (categories.includes('Yiwen (Even) Wu'))
			advisor = 'Yiwen (Even) Wu'
		if (categories.includes('Addison Cham_ibp'))
			advisor = 'Addison Cham'
		if (categories.includes('Carol Lun'))
			advisor = 'Carol Lun'
		if (categories.includes('Carol Lun (Yimin Zhu)'))
			advisor = 'Carol Lun'
		if (categories.includes('Carol Lun_ibp'))
			advisor = 'Carol Lun'
		if (categories.includes('RP-Eric Xue'))
			advisor = 'RP-Eric Xue'
		if (categories.includes('Brian Mizzi (Huxton)'))
			advisor = 'Brian Mizzi (Huxton)'
		if (categories.includes('Wei Jie (Angel) Lin'))
			advisor = 'Wei Jie (Angel) Lin'
		if (categories.includes('Xueming (Louis) Lou'))
			advisor = 'Xueming (Louis) Lou'
		if (categories.includes('RP-Meng Bai'))
			advisor = 'RP-Meng Bai'
		if (categories.includes('Jerry Song (Queen Fin)'))
			advisor = 'Jerry Song (Queen Fin)'
		if (categories.includes('RP-Arion Xiao'))
			advisor = 'RP-Arion Xiao'
		if (categories.includes('Sissi Wang'))
			advisor = 'Sissi Wang'
		if (categories.includes('Addison Cham'))
			advisor = 'Addison Cham'
		if (categories.includes('RP-Jay Peng'))
			advisor = 'RP-Jay Peng'
		if (categories.includes('Gordon Higgins'))
			advisor = 'Gordon Higgins'
		if (categories.includes('RP-Suyu (Aiden) Wu'))
			advisor = 'RP-Suyu (Aiden) Wu'
		if (categories.includes('Jiang(Richard) Chen'))
			advisor = 'Jiang(Richard) Chen'
		if (categories.includes('Jolene Zhu'))
			advisor = 'Jolene Zhu'
		if (categories.includes('RP-Fiona Ng'))
			advisor = 'RP-Fiona Ng'
		if (categories.includes('Lin (Cathy) Yang'))
			advisor = 'Lin (Cathy) Yang'
		if (categories.includes('Gordon Higgins_ibp'))
			advisor = 'Gordon Higgins_ibp'
		if (categories.includes('Luyang (Ryan) Yan'))
			advisor = 'Luyang (Ryan) Yan'
		if (categories.includes('Hong (Allen) Guan'))
			advisor = 'Hong (Allen) Guan'
		if (categories.includes('Yi (Helen) Zhang'))
			advisor = 'Yi (Helen) Zhang'
		if (categories.includes('WenRong (Linda) Li'))
			advisor = 'WenRong (Linda) Li'
		if (categories.includes('Angela Lin'))
			advisor = 'Angela Lin'
		if (categories.includes('Wilkey Chick'))
			advisor = 'Wilkey Chick'
		if (categories.includes('RP-Yue (Kevin) Guo'))
			advisor = 'RP-Yue (Kevin) Guo'
		if (categories.includes('Brian Mizzi_ibp'))
			advisor = 'Brian Mizzi_ibp'
		if (categories.includes('RP-Rio Zheng'))
			advisor = 'RP-Rio Zheng'
		return advisor
	   }
	
	countTotalAccountsValue = async(recIds:string[]) => {
		const totalAccountValueArray = await Promise.all(recIds.map(async (recId:string) => {
			return await this.getTotalPortfolioData(recId)
		}));
		let totalAccountsValue = 0;
		totalAccountValueArray.forEach((value)=>{
			this.setState((prev:any)=>{
				const totalAccountValue = prev.totalAccountValue;
				totalAccountValue.push(value);

				return{ ...prev, totalAccountValue }
			})
			if(value.endingCapital){
				totalAccountsValue += value.endingCapital;
			}
		})
		this.setState({totalAccountsValue: totalAccountsValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")});
	}

	rangeConfig = {
		rules: [{ type: 'array' as const, required: true, message: 'Please select time!' }],
	};

	onFinishTransaction = async (fieldsValue: any) => {
		// Should format date value before submit.
		const rangeValue = fieldsValue['range-picker'];
		const values = {
		  ...fieldsValue,
		  'range-picker': [rangeValue[0].format('MM-DD-YYYY'), rangeValue[1].format('MM-DD-YYYY')],
		};
		//console.log('Received values of form: ', values);
		await this.setState({ transactionStartDate: values['range-picker'][0], transactionEndDate: values['range-picker'][1] });

		const transaction_data = await this.getTransactionData(this.state.transactionStartDate, this.state.transactionEndDate, this.state.currentAccountRecId, this.state.transactionProductId);
		await this.setState({ transactionData: transaction_data.data});
		await this.render();
	};

	onClickTransactionYear = async (e:any) => {
		switch (e.target.value) {
			case 'currentYear':
				await this.setState({ transactionStartDate: moment().dayOfYear(1).format('MM-DD-YYYY'), transactionEndDate: moment().format('MM-DD-YYYY') });
				break;
			case 'latestTwoYears':
				await this.setState({ transactionStartDate: moment().dayOfYear(1).subtract(1, 'years').format('MM-DD-YYYY'), transactionEndDate: moment().format('MM-DD-YYYY') });
				break;
			case 'allYears':
//				await this.setState({ transactionStartDate: moment('01-01-2012').format('MM-DD-YYYY'), transactionEndDate: moment().format('MM-DD-YYYY') });
				await this.setState({ transactionStartDate: moment().dayOfYear(1).subtract(9, 'years').format('MM-DD-YYYY'), transactionEndDate: moment().format('MM-DD-YYYY') });
				break;			
			case 'GINKGO-S1':
				await this.setState({ transactionProductId: '253a1dc7-3bdc-40d9-98c9-75ca619fb035' });
				break;
			case 'GINKGO-S2':
				await this.setState({ transactionProductId: '4097eb52-bcd6-4f56-b470-5b44e2bf6c54' });
				break;
			case 'All':
				await this.setState({ transactionProductId: '' });
				break;
					}
		await this.delay(300)
		const transaction_data = await this.getTransactionData(this.state.transactionStartDate, this.state.transactionEndDate, this.state.currentAccountRecId, this.state.transactionProductId);
		await this.setState({ transactionData: transaction_data.data});
		await this.render();
	};

	onClickCertYear = async (e:any) => {
		switch (e.target.value) {
			case 'currentYear':
				await this.setState({ certStartDate: moment().dayOfYear(1).format('MM-DD-YYYY'), certEndDate: moment().format('MM-DD-YYYY') });
				break;
			case 'latestTwoYears':
				await this.setState({ certStartDate: moment().dayOfYear(1).subtract(1, 'years').format('MM-DD-YYYY'), certEndDate: moment().format('MM-DD-YYYY') });
				break;
			case 'allYears':
				await this.setState({ certStartDate: moment().dayOfYear(1).subtract(9, 'years').format('MM-DD-YYYY'), certEndDate: moment().format('MM-DD-YYYY') });
//				await this.setState({ certStartDate: moment('01-01-2012').format('MM-DD-YYYY'), certEndDate: moment().format('MM-DD-YYYY') });
				break;			
			case 'GINKGO-S1':
				await this.setState({ certificateProductId: '253a1dc7-3bdc-40d9-98c9-75ca619fb035' });
				break;
			case 'GINKGO-S2':
				await this.setState({ certificateProductId: '4097eb52-bcd6-4f56-b470-5b44e2bf6c54' });
				break;
			case 'All':
				await this.setState({ certificateProductId: '' });
				break;
	}
		await this.delay(300)
		const cert_data = await this.getCertData(this.state.certStartDate, this.state.certEndDate, this.state.currentAccountRecId, this.state.certificateProductId);
		await this.setState({ certData: cert_data.data});
		await this.render();
	};
	
	delay(milliseconds:any){
		return new Promise(resolve => {
		setTimeout(resolve, milliseconds);
		});
	}

	onFinishCert = async (fieldsValue: any) => {
		// Should format date value before submit.
		const rangeValue = fieldsValue['range-picker'];
		const values = {
		  ...fieldsValue,
		  //'date-picker': fieldsValue['date-picker'].format('YYYY-MM-DD'),
		  'range-picker': [rangeValue[0].format('MM-DD-YYYY'), rangeValue[1].format('MM-DD-YYYY')],
		};
		//console.log('Received values of form: ', values);
		await this.setState({ certStartDate: values['range-picker'][0], certEndDate: values['range-picker'][1] });

		const cert_data = await this.getCertData(this.state.certStartDate, this.state.certEndDate, this.state.currentAccountRecId, this.state.certificateProductId);
		await this.setState({ certData: cert_data.data});
		await this.render();

	};

	
	handleTagChange = async (e: any) => {
		
		let currentAccountRecId = e.target.id;
		this.setState( (prevState:any)=> ({ ...prevState, currentAccountRecId }));
		const currentPartnerInfo = this.state.partnersData.filter((partner:any)=>partner.recId === currentAccountRecId);
	//	this.setState({transactionProductId: '', certificateProductId: ''})
		const transaction_data = await this.getTransactionData(this.state.transactionStartDate, this.state.transactionEndDate, currentAccountRecId, this.state.transactionProductId);
		const cert_data = await this.getCertData(this.state.certStartDate, this.state.certEndDate, currentAccountRecId, this.state.certificateProductId);
		this.setState({ transactionData: transaction_data.data, certData: cert_data.data, displayAccountNum: currentPartnerInfo[0].account});
		this.render();
	};

	render() {
		
	  return (       
          <div id="portfolio">						
				<Title level={4} style={{ marginTop: '50px' }}>Portfolio</Title>
	            <div className="ant-table " style={{ display: 'flex', flex: 'auto', }}>
	                  <table>
	                    <thead className="ant-table-thead">
	                      <tr>
							<th>Account</th>
	                        <th>Shares(S1) Balance</th>
							<th>Shares(S2) Balance</th>
	                        <th>Account Value</th> 
	                      </tr>
	                    </thead>  
	                  <tbody className="ant-table-tbody">
	                      {

								this.state.totalAccountValue.map((value:any, i:number)=>(

								  <tr key={i}>
									<td><a onClick={this.handleTagChange} id={value.partnerInfo.recId}>{value.partnerInfo.category} ({value.partnerInfo.account})</a></td>
									<td>{Number(value.total_share_S1_balance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</td>
									<td>{Number(value.total_share_S2_balance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</td>
									<td>${Number(value.endingCapital).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</td>
								  </tr> 

								))
							}
 	                            <tr>
									 <td></td>
								{/*	 <td style={{textAlign:'right'}}><strong>Total Accounts Value:</strong></td> */}
									<td></td>
									<td></td>
									 <td><strong>Total Value: ${this.state.totalAccountsValue}</strong></td>

								</tr>           	                    
	                      
	                    </tbody> 
	                  </table>
					  
	            </div> 

				<div style={{marginTop: '40px'}}>
					<Form name="time_related_controls" onClick={this.onClickCertYear} onFinish={this.onFinishCert} layout="inline"
						initialValues={{'range-picker': [moment().dayOfYear(1), moment()]}}>
						{/*<Form.Item name="date-picker" label="DatePicker" {...this.config}>
							<DatePicker />
						</Form.Item>*/}
						<Form.Item name="range-picker"  {...this.rangeConfig}>
							<RangePicker format={dateFormat}/>
						</Form.Item>
						<Form.Item>
						<Button type="primary" htmlType="submit">
						Search
						</Button>
					</Form.Item>
					<Form.Item  name="year-select" >
						<Radio.Group>
						<Radio.Button value="currentYear">This Year</Radio.Button> 
						<Radio.Button value="latestTwoYears">Last Two Years</Radio.Button>
						<Radio.Button value="allYears">All Years</Radio.Button>
						</Radio.Group>
					</Form.Item>					
					<Form.Item  name="product-select" >
						<Radio.Group>
						<Radio.Button value="GINKGO-S1">GINKGO-S1</Radio.Button>
						<Radio.Button value="GINKGO-S2">GINKGO-S2</Radio.Button>
						<Radio.Button value="All">All</Radio.Button>
						</Radio.Group>
					</Form.Item>					
					</Form>
				</div>
				

				<Title level={4} >Certificates(Account:{this.state.displayAccountNum} Advisor:{this.state.advisor})</Title>

	            <div className="ant-table">
	                  <table>
	                    <thead className="ant-table-thead">
	                      <tr>
	                        <th>Certificate #</th>
							<th>Product</th>
	                        <th>Shares</th>
	                        <th>Price</th>
	                        <th>Amount Paid</th>
	                        <th>Date Issued</th>               
	                        <th>Maturity Date</th>
	                        <th>Status</th>
	                      </tr>
	                    </thead>  
	                  <tbody className="ant-table-tbody">
	                      {
	                        this.state.certData.map((cert:any, i:any)=>(                                                
	                            <tr className="ant-table-row" key={i}	                                            

								>
	                              <td>{cert.number}</td>
	                              <td>{cert.partnership?cert.partnership.account:''}</td>
	                              <td>{cert.transaction.length>0?Number(cert.transaction[0].shares).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"):''}</td>
	                              <td>{cert.transaction.length>0?cert.transaction[0].sharePrice:''}</td>
	                              <td>{cert.transaction.length>0?cert.transaction[0].amount:''}</td>
	                              <td>{cert.issuedDate?moment(cert.issuedDate, DB_dateFormat).format(dateFormat):''}</td>
								  <td>{cert.maturity? moment(cert.maturity, DB_dateFormat).format(dateFormat) : ''}</td>
					{/*			  <td>{cert.status}</td>   */}
								  <td>{
								  (
									  ()=>{
										  if (cert.status === 0) {return 'active'}
										  else if (cert.status === 1) {return 'redeemed'}
										  else if (cert.status === 2) {return 'transferred'}
										}
								  
								 
								  ) ()
								  
								  }</td>
	                            </tr>   	                                              
	                        ))
	                      }
							<tr>
							<td></td>
						   	<td></td>
						   	<td></td>
						   	<td></td>
							<td><strong>Total: ${Number(this.state.certSub_total * 10).toFixed(2)}</strong></td>
							<td></td>
							<td></td>
							<td></td>
					   		</tr>           	                    
	                    </tbody>
	                  </table>
	            </div> 

				<div style={{marginTop: '40px'}}>
					<Form name="time_related_controls"  onClick={this.onClickTransactionYear} onFinish={this.onFinishTransaction} layout="inline" 
						initialValues={{'range-picker': [moment().dayOfYear(1), moment()]}}>
  						<Form.Item name="range-picker"  {...this.rangeConfig}>
							<RangePicker format={dateFormat}/>
						</Form.Item>
 
						<Form.Item>
						<Button type="primary" htmlType="submit">
						Search
						</Button>
						</Form.Item>
 					<Form.Item  name="year-select" >
						<Radio.Group>
						<Radio.Button value="currentYear">This Year</Radio.Button>
						<Radio.Button value="latestTwoYears">Last Two Years</Radio.Button>
						<Radio.Button value="allYears">All Years</Radio.Button>
						</Radio.Group>
					</Form.Item>					
					<Form.Item  name="product-select" >
						<Radio.Group>
						<Radio.Button value="GINKGO-S1">GINKGO-S1</Radio.Button>
						<Radio.Button value="GINKGO-S2">GINKGO-S2</Radio.Button>
						<Radio.Button value="All">All</Radio.Button>
						</Radio.Group>
					</Form.Item>					
	  				</Form>
				</div>
				
				<Title level={4} >Transaction History(Account:{this.state.displayAccountNum})</Title>

	            <div className="ant-table">
	                  <table>
	                    <thead className="ant-table-thead">
	                      <tr>
	                        <th>Date</th>
							<th>Product</th>
	                        <th>Description</th>
	                        <th>Shares</th>
	                        <th>Amount</th>               
	                        <th>Fee</th>
	                        <th>Withholding</th>
	                        <th>Certificate #</th>
	                      </tr>
	                    </thead>  
	                  <tbody className="ant-table-tbody">
	                      {
	                        this.state.transactionData.map((trans:any, i:any)=>(                                               
	                            <tr className="ant-table-row" key={i}>
	                              <td>{moment(trans.recDate, DB_dateFormat).format(dateFormat)}</td>
								  <td>{trans.partnership? trans.partnership.account : ''}</td>
	                              <td>{trans.memo}</td>
								  <td>{Number(trans.shares).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</td>					
	                              <td>{trans.amount}</td>
	                              <td>{trans.penalty}</td>
								  <td>{trans.holdBack}</td>
								  <td>{trans.certificate? trans.certificate.number : ''}</td>					
	                            </tr>   
									                                              
	                        ))
	                      }
	                    </tbody>
	                  </table>
	            </div> 

          </div>          	    
	  )
}
}

export default withRouter(Portfolio);
