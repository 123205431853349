import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './home/menu';
//import App from './home/menuAdvisor';
import App from './home/menu';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + getCookie('x-auth-token');

function getCookie(cookieName: string) {
  var arrayb = document.cookie.split(";");
  for (let item of arrayb) {
    if (item.startsWith(cookieName+"=")){
        let token = item.substr(cookieName.length + 1);
        return token;
    }
  }
}


ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
