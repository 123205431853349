import React from 'react';
import axios from 'axios';
import { baseURL } from '../API/url'
//import {Button} from 'antd';
import '../App.css';
import {
  	HashRouter as Router,
  	Route,
  	Link,
	Redirect
} from 'react-router-dom';
import { withRouter } from "react-router";
import { Form, Input, Button, Checkbox, Row, Col} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

class Login extends React.Component<any,any> {
	constructor(props:any) {
	    super(props);
		
		const rememberMe = localStorage.getItem('rememberMe') === 'true';
    	const email = rememberMe ? localStorage.getItem('email') : '';

	    this.state = { 
			//data:{email:'admin@ginkgomic.com', password:'password'},
			data:{ email: email, password:'' },
			isAuthenticated: props.isAuthenticated,
			rememberMe:rememberMe
		 };
	}

	componentDidMount = () => {
		if(this.state.isAuthenticated) {
			this.props.history.push('/portfolio');
		}
	}


	onFinish = (values: any) => {
		//console.log('Received values of form: ', values); //{username: 'xxx@xxx.com', password: 'xxx', remember: boolean}
		this.setState({
			data:{email:values.username.toLowerCase(), password: values.password},
			rememberMe: values.remember,
		  });

		axios.post(baseURL+"/auth/login", this.state.data, { withCredentials: true })		
		.then((res) => {
			//console.log(res)

			if(res.data.error) {
				alert(res.data.error);
				return;
			}

			if(res.data) {
				let token = res.data.activationToken;
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + token; 
				this.props.setAuthenticate(true);
				this.props.setCurrentUser(values.username)

				// Remember me
				const { data, rememberMe } = this.state;
				localStorage.setItem('rememberMe', rememberMe);
				localStorage.setItem('email', rememberMe ? data.email : '');
		//		window.location.href = '/portfolio'
				this.props.history.push('/portfolio');
			}
			
		}, (err)=>{
			console.log('[err] ' + err);
		})
	};

	render(){
		return (
				<Row justify="center" style={{ marginTop: '50px' }}>
      			<Col span={12}>
				<Form
				layout='vertical'
				name="normal_login"
				className="login-form"
				initialValues={{username: this.state.data.email, remember: this.state.rememberMe }}
				onFinish={this.onFinish}
			>
				<Form.Item
				label="Username:"
				name="username"
				rules={[{ required: true, message: 'Please input your email!' }]}
				>
				<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
				</Form.Item>
				<Form.Item
				label="Password:"
				name="password"
				rules={[{ required: true, message: 'Please input your Password!' }]}
				>
				<Input
					prefix={<LockOutlined className="site-form-item-icon" />}
					type="password"
					placeholder="Password"
				/>
				</Form.Item>
				<Form.Item>
				<Form.Item name="remember" valuePropName="checked" noStyle>
					<Checkbox>
						Remember me
					</Checkbox>
				</Form.Item>
		
				<Link className="login-form-forgot" to="/resetPassword">
					Forgot password
				</Link>
				</Form.Item>
		
				<Form.Item>
				<Button type="primary" htmlType="submit" className="login-form-button">
					Login								   
				</Button>
				&nbsp; Or <a href="/register">Register now!</a>
				</Form.Item>
			</Form>
			</Col>
    </Row>
		)
	}
}
export default withRouter(Login);




